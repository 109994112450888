<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span>Reference: {{ invoice.reference }}</span>
            <v-spacer/>
            <v-btn
                color="info"
                @click="downloadInvoice">
              Download
              <v-icon
                  right
                  dark
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-sheet class="mx-2 rounded-b px-10 py-5 shipment-summary">
          <v-row>
            <v-col cols="8">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>Status: </td>
                    <td class="text-right">
                      <v-chip
                          label
                          :color="getStatusColor(invoice.status)"
                          class="summary-shipment-status px-10">
                        {{ getStatusTitle(invoice.status) }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>Created: </td>
                    <td class="text-right">{{ invoice.created | date }}</td>
                  </tr>
                  <tr>
                    <td>Due date: </td>
                    <td class="text-right">{{ invoice.due_date | date }}</td>
                  </tr>
                  <tr>
                    <td>Total amount: </td>
                    <td class="text-right">{{ invoice.total_amount }} {{ invoice.currency | currency }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="4">
              <v-img
                  :src="franchiseLogo"
                  height="96"
                  contain
                  class="mx-auto"/>

              <div class="text-center mt-4"><strong>{{ franchise.name }} </strong></div>
              <div class="text-center">
                {{ franchise.address.street1 }}
                {{ franchise.address.street2 }}
                {{ franchise.address.street3 }}
              </div>
              <div class="text-center">
                {{ franchise.address.city }}
                {{ franchise.address.country }},
                {{ franchise.address.postal_code }}
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card-title>
          Invoice details
        </v-card-title>
        <v-card class="mb-5">
          <v-card-text>
            <v-simple-table class="mx-10">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(line, index) in invoice.lines" :key="index">
                    <td>{{ line.title }}</td>
                    <td class="text-right">{{ line.amount }} {{ invoice.currency | currency }}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="text-right"><strong>Total: {{ invoice.total_amount.toFixed(2) }} {{invoice.currency | currency }}</strong></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        Loading...
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {invoiceStatuses} from "@/choices";
import Invoice from "@/models/Invoice";
import User from "@/models/User";
import {BACKEND_URL} from "../../../config";

export default {
  name: 'ShipmentDetails',

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    invoice() {
      return Invoice.find(this.$route.params.id)
    },
    franchise() {
      return User.query().first().franchise
    },
    franchiseLogo() {
      return `${BACKEND_URL}${this.franchise.logo}`
    }
  },

  methods: {
    downloadInvoice() {
      const url = `${BACKEND_URL}/api/v1/accounting/invoices/${this.invoice.id}/download/`
      window.open(url)
    },
    getStatusColor(status) {
      return invoiceStatuses.find(choice => choice.value === status).color
    },
    getStatusTitle(status) {
      return invoiceStatuses.find(choice => choice.value === status).title
    }
  },

  async created() {
    await Invoice.api().get(`/api/v1/accounting/invoices/${this.$route.params.id}?expand=lines`);
    this.loading = false;
  }
}
</script>

<style scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>